.addUser {
    max-width: 500px;
    width: 100%;
    padding: 0px 20px;
    margin: 0 auto;
    box-sizing: border-box;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.addUser-header {
    margin: 0 0 20px 0;
}

.addUser-content {
    text-align: center;
}