.header {
  border: none;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);
  bottom: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  height: 65px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 100;
  background-color: #fff;
  color: #191919;
}

.header__inner {
  margin: 0 auto;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: inherit;
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1440px;
}

.header__logo-block {
  margin-left: 30px;
  max-width: 299px;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  margin: 0 10px 0 20px;
  box-sizing: border-box;
}

.header__logo-block .logo {
  min-width: 90px;
}

.header .navbar {
  display: flex;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  text-align: center;
  width: auto;
  overflow: visible;
  position: static;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  font-family: TTHoves-Regular, helvetica, arial, verdana, sans-serif;
}

.header .navbar .header-menu {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: inherit;
  margin: 0;
  padding: 0;
}

.header .navbar .menu-item {
  position: relative;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 15px;
  padding-left: 0;
  box-sizing: border-box;
  text-align: center;
  width: 41px;
  height: 41px;
  font-family: TTHoves-Regular,helvetica,arial,verdana,sans-serif;
  font-size: 12px;
  line-height: 14px;
  width: auto;
}

.header .navbar .menu-item .nav-link {
  text-decoration: none;
  height: auto;
  color: inherit;
}

.header .navbar .navbar-collapse {
  display: block;
    width: 100%;
    margin: 0 auto;
    background-color: inherit;
}

.header .navbar,
.header .navbar .menu-item {
  font-size: 14px;
  line-height: 18px;
}

.header .lang-switcher__item,
.header .lang-switcher__selected,
.header .navbar .menu-item {
  width: 65px;
  height: 65px
}

.header .navbar .navbar-collapse {
  display: block;
  width: 100%;
  margin: 0 auto;
  background-color: inherit;
}

footer {
  background-color: #191919;
  min-width: 320px;
  overflow: hidden;
  position: fixed;
  bottom: -50px;
  z-index: -1;
}

footer .footer-waves {
  background-color: #fff;
}

footer .footer-waves img, footer .footer-waves video {
  width: 105%;
  margin-left: -4%;
  margin-bottom: -5px;
}

.content {
  padding: 65px 0 100px 0;
}

/* .content::after {
  content: '';
  display: table;
  clear: both;
} */

@font-face {
	font-family:TTHoves-Regular;
	font-weight: normal;
	src:url("./assets/TT-Hoves-Regular.otf") format("opentype");
}