.register {
    max-width: 500px;
    width: 100%;
    padding: 0px 20px;
    margin: 0 auto;
    box-sizing: border-box;
}
.input>.av-input {
    border-radius: 0;
    -webkit-appearance: none;
    font-size: 18px;
    line-height: 27px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-bottom: 1px solid #d1d1d1;
    box-shadow: none;
    background-color: transparent;
    font-family: TTHoves-Regular,helvetica,arial,verdana,sans-serif;
    color: #191919;
    z-index: 2;
    outline: none;
    padding-top: 11px;
    overflow: visible;
    margin: 0;
}

.input>input:focus {
    border-bottom: 1px solid #191919;
}

.input>input:invalid {
    border-bottom: 1px solid #be0000;
}

label {
    position: relative;
    top: -2px;
}

.av-error-msg {
    font-size: 12px;
    padding-top: 1px;
    color: #be0000;
    opacity: 0;
}

.input>input:invalid + .av-error-msg {
    opacity: 1;
}

.av-button {
    background-color: #8444e3;
    color: #fff;
    border-color: #fff;
    width: auto;
    border-radius: 30px;
    padding: 12px 20px;
    display: inline-block;
    font-size: 14px;
    line-height: 1.14286;
    position: relative;
    text-decoration: none;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
    white-space: pre-wrap;
    font-family: TTHoves-Regular,helvetica,arial,verdana,sans-serif;
    z-index: 2;
    outline: none;
    cursor: pointer;
    box-shadow: none;
}

.av-button:hover {
    border: 1px solid #191919;
    border-color: #8444e3;
    color: #8444e3;
    background-color: #fff;
}

.register__play {
    margin: 10px 0;
    text-align: center;
}

.register__errorMsg {
    color: red;
}