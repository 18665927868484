li {
    font-family: TTHoves-Regular,helvetica,arial,verdana,sans-serif;
    font-size: 18px;
    line-height: 27px;
}

.leaderBoard {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
}

.leaderBoard__leftContent {
    float: left;
    width: 400px;
    box-sizing: border-box;
}

.leaderBoard__list {
    margin: 0;
    padding: 10px 0 40px 20px;
}

.leaderBoard__video {
    float: left;
    width: calc(100% - 400px);
    max-width: 1000px;
}

.leaderBoard__videoSrc {
    max-width: 100%;
}